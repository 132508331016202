[
  {
    "quote": "I love the exercises and the fact I have so much content to keep me going. It isn't a diet plan, it's not all or nothing and I love that it fits within my life.",
    "name": "Donna, 37",
    "altText": "Donna, 37",
    "images": [
      {
        "src": "img/transformations/donna.png",
        "dpr": "1x",
        "format": "png"
      },
      {
        "src": "img/transformations/donna@2x.png",
        "dpr": "2x",
        "format": "png"
      },
      {
        "src": "img/transformations/donna.webp",
        "dpr": "1x",
        "format": "webp"
      },
      {
        "src": "img/transformations/donna@2x.webp",
        "dpr": "2x",
        "format": "webp"
      }
    ]
  },
  {
    "quote": "Thank you to Joe and The Body Coach team and everyone here for getting me through everyday, I couldn't have done it without you!!",
    "name": "Stacy, 43",
    "altText": "Stacy, 43",
    "images": [
      {
        "src": "img/transformations/stacy-sanderson.png",
        "dpr": "1x",
        "format": "png"
      },
      {
        "src": "img/transformations/stacy-sanderson@2x.png",
        "dpr": "2x",
        "format": "png"
      },
      {
        "src": "img/transformations/stacy-sanderson.webp",
        "dpr": "1x",
        "format": "webp"
      },
      {
        "src": "img/transformations/stacy-sanderson@2x.webp",
        "dpr": "2x",
        "format": "webp"
      }
    ]
  },
  {
    "quote": "I am the fittest and healthiest I've ever been at 41 and I'm in a good place with myself. It's really been invaluable.",
    "name": "Rebecca, 41",
    "altText": "Rebecca, 41",
    "images": [
      {
        "src": "img/transformations/rebecca.png",
        "dpr": "1x",
        "format": "png"
      },
      {
        "src": "img/transformations/rebecca@2x.png",
        "dpr": "2x",
        "format": "png"
      },
      {
        "src": "img/transformations/rebecca.webp",
        "dpr": "1x",
        "format": "webp"
      },
      {
        "src": "img/transformations/rebecca@2x.webp",
        "dpr": "2x",
        "format": "webp"
      }
    ]
  },
  {
    "quote": "Two and a half years I've been on this plan and it has dramatically changed my life for the better.",
    "name": "Stacy, 36",
    "altText": "Stacy, 36",
    "images": [
      {
        "src": "img/transformations/stacy-ford.png",
        "dpr": "1x",
        "format": "png"
      },
      {
        "src": "img/transformations/stacy-ford@2x.png",
        "dpr": "2x",
        "format": "png"
      },
      {
        "src": "img/transformations/stacy-ford.webp",
        "dpr": "1x",
        "format": "webp"
      },
      {
        "src": "img/transformations/stacy-ford@2x.webp",
        "dpr": "2x",
        "format": "webp"
      }
    ]
  }
]
