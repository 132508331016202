{
  "title": "Get started with<0 /><1>$t(common.tbc) App</1>",
  "whatYoullGet": "What you'll get...",
  "features": [
    "A structured plan tailored to you and your goals",
    "Brand new recipes and workouts every month",
    "In-app planning tools to help you stay on track",
    "Regular live workouts, challenges and series"
  ],
  "createAccount": "Create account",
  "noPlanSelectedError": "Please select a plan.",
  "unableToFetchPlansError": "Sorry, we're having issues finding the available subscription plans right now. Please try again later.",
  "redeemCoupon": "Redeem coupon",
  "couponCode": "Coupon code",
  "couponApplied": "<0>{{coupon}}</0> coupon applied",
  "couponInvalid": "Please enter a valid code and try again",
  "ratings": "{{count}}k ratings",
  "transformations": "Transformations",
  "appStoreEditorsChoice": "App Store Editors Choice",
  "playStoreBestApp": "Play Store Best App"
}
