{
  "title": "Choose how to pay",
  "paymentDetails": "Payment details",
  "confirm": "Confirm",
  "paymentError": "There was an error processing your payment. Please try again.",
  "paymentNotRequired": {
    "title": "Payment not required",
    "message": "Looks like you've taken a wrong turn. Payment is not required for this subscription. Please return to the <a href=\"/\">home page</a> and start again."
  }
}
