// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/locales/en-GB/index.ts"
);
import.meta.hot.lastModified = "1731405470897.1924";
}
// REMIX HMR END

import common from './common.json'
import error from './error.json'
import index from './index.json'
import details from './details.json'
import payment from './payment.json'
import confirmation from './confirmation.json'
import transformationList from './transformation-list.json'
import featureList from './feature-list.json'
import existingSubscription from './existing-subscription.json'
import { Locale } from '../locale'

export default {
  common,
  error,
  index,
  details,
  payment,
  confirmation,
  existingSubscription,
  transformationList,
  featureList
} as Locale
