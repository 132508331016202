{
  "contactASupportHero": "Contact a support hero",
  "generic": {
    "title": "Whoops!",
    "message": "Sorry, something went wrong. Our engineers have been made aware of the issue and will work to fix it as soon as possible."
  },
  "notFound": {
    "title": "Not found",
    "message": "Sorry, we couldn't find that page."
  },
  "payment": {
    "title": "Error getting payment information",
    "message": "There was an issue getting information about the status of your payment. If you've received an invoice or confirmation email, the payment has been received and you can safely disregard this message. If not, please contact a support hero."
  },
  "maintenance": {
    "title": "Maintenance",
    "message": "We're currently undergoing some maintenance work.",
    "subscribeOnAppStore": "You can still subscribe via the Google Play Store or Apple App Store, depending on your device."
  }
}
